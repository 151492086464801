import useCurrentTab from './useCurrentTab'

const useHomeInsertsVisibility = () => {
  const currentTab = useCurrentTab()

  return {
    isStickyPromoBoxEnabled: currentTab.isStickyPromoBoxEnabled,
    areInsertsEnabled: currentTab.feed.areInsertsEnabled,
  }
}

export default useHomeInsertsVisibility
