import useLocation from 'hooks/useLocation'

import { getTabName } from '../utils/tabs'
import { TABS } from '../constants'

const useCurrentTab = () => {
  const location = useLocation()
  const currentTabName = getTabName(location.urlParams.tab)

  return TABS[currentTabName]
}

export default useCurrentTab
