'use client'

import { Icon, Button } from '@vinted/web-ui'
import { X12 } from '@vinted/monochrome-icons'
import { useSelector } from 'react-redux'

import { clickEvent } from 'libs/common/event-tracker/events'

import { getScreenName } from 'state/screen/selectors'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  onClick?: () => void
}

const CloseButton = ({ onClick }: Props) => {
  const { track } = useTracking()
  const screenName = useSelector(getScreenName)
  const translate = useTranslate('shipping_fees_applied_info_banner')

  const handleBannerClose = () => {
    onClick?.()
    track(
      clickEvent({
        target: ClickableElement.CloseShippingInfoBanner,
        screen: screenName,
      }),
    )
  }

  return (
    <Button
      size={Button.Size.Small}
      styling={Button.Styling.Flat}
      theme="amplified"
      icon={<Icon name={X12} />}
      onClick={handleBannerClose}
      aria={{ 'aria-label': translate('actions.a11y.close') }}
      testId="shipping-fees-apply-close-button"
    />
  )
}

export default CloseButton
