'use client'

import { useSelector } from 'react-redux'

import { getIsAuthenticated } from 'state/session/selectors'

import useAbTest from 'hooks/useAbTest'

import { AbTestVariant } from 'constants/abtest'
import { Screen } from 'constants/tracking/screens'
import { getScreenName } from 'state/screen/selectors'

import StaticBanner from './StaticBanner'
import StickyBanner from './StickyBanner'
import DismissibleStickyBanner from './DismissibleStickyBanner'

const CompliantFeesApplyBanner = () => {
  const screenName = useSelector(getScreenName)
  const isLoggedIn = useSelector(getIsAuthenticated)
  const compliantShippingBannerTest = useAbTest({
    abTestName: 'info_banner_shipping_feed_sticky_v1',
    shouldTrackExpose: screenName === Screen.NewsFeed,
  })

  if (!isLoggedIn) {
    return null
  }

  switch (compliantShippingBannerTest?.variant) {
    case AbTestVariant.A:
      return <StaticBanner />
    case AbTestVariant.B:
      return <StickyBanner />
    case AbTestVariant.C:
      return <DismissibleStickyBanner />
    default:
      return null
  }
}

export default CompliantFeesApplyBanner
