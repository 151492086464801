import { TabName } from './types'

type TabConfig = {
  titleKey: `homepage.tabs.${string}`
  name: TabName
  isStickyPromoBoxEnabled: boolean
  isSellerPromotionEnabled: boolean
  feed: {
    titleKey: `homepage.${string}`
    areInsertsEnabled: boolean
  }
}

export const TABS = {
  all: {
    titleKey: 'homepage.tabs.all.title',
    name: 'all',
    isStickyPromoBoxEnabled: true,
    isSellerPromotionEnabled: true,
    feed: {
      titleKey: 'homepage.feed.title',
      areInsertsEnabled: true,
    },
  },
  designer: {
    titleKey: 'homepage.tabs.designer.title',
    name: 'designer',
    isStickyPromoBoxEnabled: false,
    isSellerPromotionEnabled: false,
    feed: {
      titleKey: 'homepage.tabs.designer.feed_title',
      areInsertsEnabled: false,
    },
  },
} satisfies Record<TabName, TabConfig>

export const TAB_NAMES = Object.keys(TABS) as Array<keyof typeof TABS>
