'use client'

import { Component, JSXElementConstructor, MouseEvent } from 'react'

import { getDisplayName } from 'libs/utils/react'
import { clickListItemEvent, ClickListItemEventArgs } from 'libs/common/event-tracker/events'
import { GoogleTagManagerEvent } from 'constants/google'
import { googleTagManagerTrack } from 'data/utils/google'
import { ListItemContentType } from 'constants/tracking/content-types'

import withTracking, { EventTrackerProps } from './wrapper'

type ClickListItemProps = EventTrackerProps & {
  tracking: ClickListItemEventArgs
  onClick?: (event: MouseEvent) => void
}

function addClickListItemTracking<P, C>(WrappedComponent: JSXElementConstructor<P> & C) {
  type Props = JSX.LibraryManagedAttributes<C, Omit<P, keyof ClickListItemProps>>

  class TrackedComponent extends Component<Props & ClickListItemProps> {
    static displayName = getDisplayName(WrappedComponent)

    onClick = (event: MouseEvent) => {
      this.props.track(clickListItemEvent(this.props.tracking))

      if (this.props.tracking.contentType === ListItemContentType.Item)
        googleTagManagerTrack(GoogleTagManagerEvent.SelectItem, {
          item_id: this.props.tracking.id,
        })

      if (this.props.onClick) this.props.onClick(event)
    }

    render() {
      return <WrappedComponent {...(this.props as any)} onClick={this.onClick} />
    }
  }

  return withTracking(TrackedComponent)
}

export { addClickListItemTracking }
