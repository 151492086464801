export enum InsightsTabName {
  Impressions = 'impressions',
  ProfileVisits = 'profile_visits',
  ItemVisits = 'item_visits',
}

export enum ClosetPromotionEngagementEventTarget {
  AllItems = 'all_items',
  UserProfile = 'user_profile',
  UserFollow = 'user_follow',
  ItemFavourite = 'item_favourite',
  Item = 'item',
  ViewProfile = 'view_wardrobe',
}
