'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useFetch from 'hooks/useFetch'
import useLocation from 'hooks/useLocation'

import { ExtraServiceCheckout } from 'components/ExtraService'
import ContentLoader from 'components/ContentLoader'
import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'
import { navigateToSingleCheckout } from 'libs/utils/checkout'

import { clickEvent } from 'libs/common/event-tracker/events'
import {
  ExtraServiceOrderType,
  ExtraServiceCheckoutModal,
  extraServiceOrderTypeToTrackingTypeMap,
} from 'constants/extra-service'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { UiState } from 'constants/ui'
import { reloadPage } from 'libs/utils/window'
import { getClosetPromotionPricing, initiateSingleCheckout } from 'data/api'
import { transformClosetPromotionPricingResponse } from 'data/api/transformers/response'
import { removeParamsFromQuery } from 'libs/utils/url'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import useClosetPromotionOrder from './hooks/useClosetPromotionOrder'
import ClosetPromotionOrderConfirmationModal from './ClosetPromotionOrderConfirmationModal'
import ClosetPromotionPreCheckoutModal from './ClosetPromotionPreCheckoutModal'
import ClosetPromotionPreCheckoutHelpModal from './ClosetPromotionPreCheckoutHelpModal'
import ClosetPromotionDynamicPricingInfoModal from './ClosetPromotionDynamicPricingInfoModal'

type Props = {
  onCheckoutSuccess?: () => void
  isOpen: boolean
  handlePrecheckoutClose: () => void
}

const ClosetPromotionCheckout = ({
  onCheckoutSuccess = reloadPage,
  isOpen,
  handlePrecheckoutClose,
}: Props) => {
  const { track } = useTracking()
  const { cp_precheckout: closetPromotionPrecheckoutParam } = useLocation().searchParams
  const { replaceHistoryState, relativeUrl, urlQuery } = useLocation()

  const [isProceedToCheckoutClicked, setIsProceedToCheckoutClicked] = useState(false)
  const [uiState, setUiState] = useState(UiState.Idle)
  const [activeModal, setActiveModal] = useState<ExtraServiceCheckoutModal>(
    ExtraServiceCheckoutModal.None,
  )

  const isUnifiedCheckoutEnabled = useSelector(
    getIsFeatureSwitchEnabled('unified_checkout_screen_web'),
  )

  const {
    fetch: fetchClosetPromotionPricing,
    transformedData: closetPromotionPricing,
    isLoading: isClosetPromotionPricingLoading,
    error: closetPromotionPricingError,
  } = useFetch(getClosetPromotionPricing, transformClosetPromotionPricingResponse)

  const { order, isOrderLoading, prepareClosetPromotionOrder, getClosetPromotionOrder } =
    useClosetPromotionOrder()

  useEffect(() => {
    if (activeModal === ExtraServiceCheckoutModal.PreCheckout && !closetPromotionPricing) {
      fetchClosetPromotionPricing()
    }
  }, [fetchClosetPromotionPricing, activeModal, closetPromotionPricing])

  useEffect(
    function startPreCheckoutFromParam() {
      if (closetPromotionPrecheckoutParam !== 'true') return

      setActiveModal(ExtraServiceCheckoutModal.PreCheckout)
    },
    [closetPromotionPrecheckoutParam],
  )

  useEffect(
    function startPreCheckoutFromProp() {
      if (!isOpen) return

      setActiveModal(ExtraServiceCheckoutModal.PreCheckout)
    },
    [isOpen],
  )

  useEffect(
    function startCheckoutAfterOrderPrepare() {
      if (!order) return
      if (!isProceedToCheckoutClicked) return

      track(
        clickEvent({
          screen: Screen.ClosetPromotionOrderReview,
          target: ClickableElement.ReviewClosetPromotionOrder,
          targetDetails: order.id.toString(),
        }),
      )

      if (isUnifiedCheckoutEnabled) {
        const orderId = order?.id
        const orderType = ExtraServiceOrderType.ClosetPromotion

        setUiState(UiState.Pending)

        const fetchCheckoutId = async () => {
          const response = await initiateSingleCheckout({
            id: orderId.toString(),
            type: extraServiceOrderTypeToTrackingTypeMap[orderType],
          })

          if ('errors' in response) {
            setUiState(UiState.Failure)

            setActiveModal(ExtraServiceCheckoutModal.GenericErrorModal)

            return
          }

          navigateToSingleCheckout(response?.checkout?.id, orderId, orderType)
        }

        fetchCheckoutId()
      } else {
        setActiveModal(ExtraServiceCheckoutModal.Checkout)
      }

      setIsProceedToCheckoutClicked(false)
    },
    [order, track, isUnifiedCheckoutEnabled, isProceedToCheckoutClicked],
  )

  function openPreCheckoutModal() {
    setActiveModal(ExtraServiceCheckoutModal.PreCheckout)
  }

  function openDynamicPricingInfoModal() {
    setActiveModal(ExtraServiceCheckoutModal.DynamicPricingInfo)

    track(
      clickEvent({
        screen: Screen.ClosetPromotionOrderReview,
        target: ClickableElement.ClosetPromotionDynamicPricingInfo,
      }),
    )
  }

  function closeCheckoutModal() {
    if (closetPromotionPrecheckoutParam === 'true') {
      const urlWithoutParam = removeParamsFromQuery(relativeUrl, urlQuery, ['cp_precheckout'])
      replaceHistoryState(urlWithoutParam)
    }
    setActiveModal(ExtraServiceCheckoutModal.None)
    handlePrecheckoutClose()
  }

  function handleCheckoutFinish(isCheckoutSuccess: boolean) {
    closeCheckoutModal()

    if (isCheckoutSuccess) {
      onCheckoutSuccess()
    }
  }

  function handleCheckoutInitAfterCardAuth(orderId: number) {
    getClosetPromotionOrder(orderId)
    setActiveModal(ExtraServiceCheckoutModal.Checkout)
  }

  function handlePreCheckoutConfirm() {
    if (!order) {
      prepareClosetPromotionOrder()
    }
    setIsProceedToCheckoutClicked(true)
  }

  function handlePreCheckoutPreview() {
    track(
      clickEvent({
        screen: Screen.ClosetPromotionOrderReview,
        target: ClickableElement.ClosetPromotionPreCheckoutHelp,
      }),
    )

    setActiveModal(ExtraServiceCheckoutModal.PreCheckoutHelp)
  }

  if (uiState === UiState.Pending) {
    return (
      <Modal show>
        <ContentLoader testId="closet-promotion-checkout-loader" />
      </Modal>
    )
  }

  return (
    <>
      <ClosetPromotionPreCheckoutModal
        pricing={closetPromotionPricing}
        isPricingLoading={isClosetPromotionPricingLoading}
        isOrderLoading={isOrderLoading}
        error={closetPromotionPricingError}
        show={activeModal === ExtraServiceCheckoutModal.PreCheckout}
        onNextAction={handlePreCheckoutConfirm}
        onPreview={handlePreCheckoutPreview}
        onDynamicPricingInfo={openDynamicPricingInfoModal}
        onBack={closeCheckoutModal}
      />
      <ClosetPromotionPreCheckoutHelpModal
        show={activeModal === ExtraServiceCheckoutModal.PreCheckoutHelp}
        onBack={openPreCheckoutModal}
      />
      <ClosetPromotionDynamicPricingInfoModal
        show={activeModal === ExtraServiceCheckoutModal.DynamicPricingInfo}
        onBack={openPreCheckoutModal}
      />
      <ExtraServiceCheckout
        show={activeModal === ExtraServiceCheckoutModal.Checkout && !isUnifiedCheckoutEnabled}
        orderType={ExtraServiceOrderType.ClosetPromotion}
        orderId={order?.id}
        orderPayable={order?.payable}
        addCardSourceParams={{
          type: ExtraServiceOrderType.ClosetPromotion,
        }}
        onBack={openPreCheckoutModal}
        onCheckoutFinish={handleCheckoutFinish}
        onInitStartAfterCardAuth={handleCheckoutInitAfterCardAuth}
        orderConfirmationModal={props => (
          <ClosetPromotionOrderConfirmationModal
            show={props.show}
            onBack={props.onBack}
            onConfirmOrder={props.onConfirmOrder}
            onShowSalesTaxModal={props.onShowSalesTaxModal}
            onPaymentMethodSelect={props.onPaymentMethodSelect}
            order={order}
          />
        )}
      />
      <GenericErrorModal
        isShown={activeModal === ExtraServiceCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default ClosetPromotionCheckout
