'use client'

import { Cell, Text } from '@vinted/web-ui'

import { PRICELIST_URL, TERMS_URL } from 'constants/routes'
import useTranslate from 'hooks/useTranslate'

interface Props {
  theme?: React.ComponentProps<typeof Text>['theme']
}

const ExtraServicePolicyNote = ({ theme }: Props) => {
  const translate = useTranslate('extra_services')

  function renderLink(url: string, content: string) {
    return (
      <a href={url} target="blank" rel="noopener noreferrer">
        {content}
      </a>
    )
  }

  return (
    <Cell theme="transparent">
      <Text theme={theme}>
        {translate('checkout.policy_note', {
          terms: renderLink(TERMS_URL, translate('terms')),
          price_list: renderLink(PRICELIST_URL, translate('price_list')),
        })}
      </Text>
    </Cell>
  )
}

export default ExtraServicePolicyNote
