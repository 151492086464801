'use client'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Cell,
  Text,
  Button,
  Spacer,
  Icon,
  Divider,
  BottomSheet,
  Modal,
  Navigation,
} from '@vinted/web-ui'
import { VasClosetPromo48 } from '@vinted/multichrome-icons'
import { QuestionCircle16, X24 } from '@vinted/monochrome-icons'

import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { clickClosetPromotionEvent, clickEvent } from 'libs/common/event-tracker/events'
import { getPromotedClosetContentSource } from 'libs/utils/contentSource'
import { ClosetModel, VasEntryPointModel } from 'types/models'
import { getIsAuthenticated } from 'state/session/selectors'
import { postClosetPromotionInteractions } from 'data/api'

import Closet from '../Closet'
import ClosetPromotionCheckout from './ClosetPromotionCheckout'

type Props = {
  wide?: boolean
  isWhiteLayout?: boolean
  banner?: VasEntryPointModel
  closet: ClosetModel
  position: number
  contentSource?: ContentSource
  homepageSessionId?: string
}

const ClosetPromotion = ({
  wide = false,
  isWhiteLayout = true,
  banner,
  closet,
  position,
  contentSource,
  homepageSessionId,
}: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const translate = useTranslate('closet_promotion.listing')
  const isUserLoggedIn = useSelector(getIsAuthenticated)

  const [isHelpVisible, setIsHelpVisible] = useState(false)
  const [isPrecheckoutOpen, setIsPrecheckoutOpen] = useState(false)

  function handlePrecheckoutOpen() {
    setIsPrecheckoutOpen(true)
  }

  function handlePrecheckoutClose() {
    setIsPrecheckoutOpen(false)
  }

  function handleCheckoutButtonClick() {
    handlePrecheckoutOpen()
    track(
      clickEvent({
        target: ClickableElement.PromoteCloset,
      }),
    )
  }

  function handleHelpClick() {
    setIsHelpVisible(true)

    track(
      clickClosetPromotionEvent({
        ownerId: closet.user.id,
        contentSource: getPromotedClosetContentSource(contentSource),
        target: ClickableElement.ClosetPromotionHelp,
      }),
    )

    if (!isUserLoggedIn) return

    postClosetPromotionInteractions({
      ownerUserId: closet.user.id,
      interactionType: ClickableElement.ClosetPromotionHelp,
    })
  }

  function handleHelpClose() {
    setIsHelpVisible(false)
  }

  function renderClosetPromotionCheckoutButton({ buttonTitle }: VasEntryPointModel) {
    return (
      <Button
        text={buttonTitle}
        styling={Button.Styling.Filled}
        onClick={handleCheckoutButtonClick}
        testId="closet-promotion-checkout-button"
      />
    )
  }

  function renderClosetPromotionBanner() {
    if (!banner || !closet.showBanner) return null

    return (
      <>
        {isWhiteLayout && <Spacer />}
        <Divider />
        <Cell
          title={banner.title}
          body={banner.subtitle}
          suffix={renderClosetPromotionCheckoutButton(banner)}
          prefix={<Icon name={VasClosetPromo48} />}
        />
      </>
    )
  }

  function renderHelpPopup() {
    if (breakpoints.phones) {
      return (
        <BottomSheet
          isVisible={isHelpVisible}
          onClose={handleHelpClose}
          title={translate('help.title')}
          closeButtonEnabled
          closeOnOverlayClick
        >
          <Cell styling={Cell.Styling.Wide} body={translate('help.body')} />
        </BottomSheet>
      )
    }

    return (
      <Modal show={isHelpVisible} defaultCallback={handleHelpClose} closeOnOverlay>
        <Navigation
          body={
            <Cell>
              <Text text={translate('help.title')} type={Text.Type.Title} as="span" />
            </Cell>
          }
          right={
            <Button
              styling={Button.Styling.Flat}
              onClick={handleHelpClose}
              iconName={X24}
              theme="amplified"
              inline
              testId="closet-promotion--help-close-button"
            />
          }
        />
        <Divider />
        <Cell styling={Cell.Styling.Wide} body={translate('help.body')} />
      </Modal>
    )
  }

  function renderHelp() {
    return (
      <>
        <div className="u-flexbox u-align-items-center u-ui-padding-left-medium">
          <Text type={Text.Type.Subtitle} as="span">
            {translate('title')}
          </Text>
          <Button
            styling={Button.Styling.Flat}
            iconName={QuestionCircle16}
            iconColor={Icon.Color.GreyscaleLevel3}
            size={Button.Size.Medium}
            inline
            onClick={handleHelpClick}
            testId="closet-promotion--help-button"
          />
        </div>
        {renderHelpPopup()}
      </>
    )
  }

  return (
    <>
      <Closet
        {...closet}
        wide={wide}
        position={position}
        contentSource={contentSource}
        homepageSessionId={homepageSessionId}
      />
      {renderHelp()}
      {renderClosetPromotionBanner()}
      {!!banner && closet.showBanner && (
        <ClosetPromotionCheckout
          isOpen={isPrecheckoutOpen}
          handlePrecheckoutClose={handlePrecheckoutClose}
        />
      )}
    </>
  )
}

export default ClosetPromotion
