import { useEffect } from 'react'

import { getVasEntryPoints } from 'data/api'
import { transformVasEntryPointsResponse } from 'data/api/transformers/response'

import { VasEntryPoint } from 'constants/vas-entry-point'

import useHomeInsertsVisibility from 'pages/Home/hooks/useHomeInsertsVisibility'

import useFetch from './useFetch'

const useFetchVasEntryPoints = (entryPointNames: Array<VasEntryPoint>) => {
  const {
    fetch: fetchVasEntryPoints,
    transformedData: data,
    isLoading,
    error,
  } = useFetch(getVasEntryPoints, transformVasEntryPointsResponse)
  const { areInsertsEnabled } = useHomeInsertsVisibility()

  useEffect(() => {
    if (!areInsertsEnabled) return

    fetchVasEntryPoints({ bannerNames: entryPointNames })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVasEntryPoints, areInsertsEnabled])

  return { data, isLoading, error }
}

export default useFetchVasEntryPoints
