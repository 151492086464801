'use client'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, Cell, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { abTestExposeEvent } from 'libs/common/event-tracker/events'
import { getExposee } from 'state/session/selectors'
import { getAbTestByName } from 'state/ab-tests/selectors'
import { AbTestVariant } from 'constants/abtest'

const Banner = () => {
  const exposee = useSelector(getExposee)
  const { track } = useTracking()
  const shippingFeesInfoBannerV1 = useSelector(
    getAbTestByName('info_banner_shipping_fees_apply_feed'),
  )
  const shippingFeesInfoBannerV2 = useSelector(
    getAbTestByName('info_banner_shipping_fees_apply_feed_v2'),
  )

  const translate = useTranslate('shipping_fees_applied_info_banner')

  useEffect(() => {
    if (!shippingFeesInfoBannerV1) return

    track(
      abTestExposeEvent({ ...exposee, ...(shippingFeesInfoBannerV2 || shippingFeesInfoBannerV1) }),
    )
  }, [shippingFeesInfoBannerV2, shippingFeesInfoBannerV1, exposee, track])

  const getShippingBannerTitle = () => {
    if (!shippingFeesInfoBannerV1 || shippingFeesInfoBannerV1.variant === AbTestVariant.Off) {
      return null
    }

    const getTranslationKey = () => {
      switch (shippingFeesInfoBannerV2?.variant) {
        case AbTestVariant.A:
          return 'title_with_average'
        case AbTestVariant.B:
          return 'title_with_range'
        case AbTestVariant.C:
          return 'title_with_range_and_average'
        default:
          return 'title'
      }
    }

    return translate(getTranslationKey())
  }

  const bannerTitle = getShippingBannerTitle()

  if (!bannerTitle) return null

  return (
    <Card testId="shipping-fees-applied-banner">
      <Cell title={<Text text={bannerTitle} type={Text.Type.Subtitle} />} theme="transparent" />
    </Card>
  )
}

export default Banner
