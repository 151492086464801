'use client'

import { useState } from 'react'
import { Button, Image, Text } from '@vinted/web-ui'
import { X12 } from '@vinted/monochrome-icons'

import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'

import { BreakpointMap } from 'types/components'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ListerActivationBannerModel } from 'types/models'

type BreakpointItemCount = {
  default: number
} & Partial<Record<keyof BreakpointMap, number>>

type Props = {
  breakpointItemCount: BreakpointItemCount
  banner: ListerActivationBannerModel
  onClose: (banner: ListerActivationBannerModel) => void
}

const ListerActivationBanner = ({ breakpointItemCount, banner, onClose }: Props) => {
  const { active } = useBreakpoint()
  const [isClosed, setIsClosed] = useState(false)
  const { track } = useTracking()

  function handleClose() {
    track(
      clickEvent({
        target: ClickableElement.CloseListerActivationBanner,
        targetDetails: banner.catalogId.toString(),
      }),
    )

    setIsClosed(true)

    onClose(banner)
  }

  function handleLinkClick() {
    track(
      clickEvent({
        target: ClickableElement.UploadAfterListerActivationBanner,
        targetDetails: banner.catalogId.toString(),
      }),
    )
  }

  function getBreakpointPhotoItems(photoItems: Array<string>) {
    let itemCount = breakpointItemCount.default
    const targetBreakpoint = active.find(breakpoint => breakpointItemCount[breakpoint])

    if (targetBreakpoint)
      itemCount = breakpointItemCount[targetBreakpoint] || breakpointItemCount.default

    return photoItems.slice(0, itemCount)
  }

  function renderCloseButton() {
    return (
      <div className="lister-activation-banner__close">
        <Button
          inline
          styling={Button.Styling.Flat}
          iconName={X12}
          size={Button.Size.Small}
          onClick={handleClose}
          testId="lister-activation-banner-close-button"
        />
      </div>
    )
  }

  function renderPhotoItems(photoItems: Array<string>) {
    return (
      <div className="lister-activation-banner__photos">
        {photoItems.map(item => (
          <div className="lister-activation-banner__photo-item" key={item}>
            <Image styling={Image.Styling.Rounded} ratio={Image.Ratio.SmallPortrait} src={item} />
          </div>
        ))}
      </div>
    )
  }

  function renderBottomInfo() {
    return (
      <div className="lister-activation-banner__bottom-info">
        <div className="lister-activation-banner__bottom-info-text">
          <Text type={Text.Type.Subtitle} text={banner.subtitle} />
          <Text type={Text.Type.Heading} text={banner.title} />
        </div>
        <div className="lister-activation-banner__bottom-info-action">
          <Button
            styling={Button.Styling.Filled}
            text={banner.buttonLinkText}
            onClick={handleLinkClick}
            url={banner.buttonLinkUrl}
          />
        </div>
      </div>
    )
  }

  if (isClosed) return null

  return (
    <div className="lister-activation-banner">
      {renderCloseButton()}
      {renderPhotoItems(getBreakpointPhotoItems(banner.imageUrls))}
      {renderBottomInfo()}
    </div>
  )
}

export default ListerActivationBanner
