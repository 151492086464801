export enum VasEntryPoint {
  PromoteListings = 'promote_listings',
  PromotedClosets = 'promoted_closets',
  ProfilePromotedClosets = 'profile_promoted_closets',
  PromotedClosetsStats = 'promoted_closets_stats',
  Collections = 'collections',
  PushUps = 'push_ups',
}

export enum VasImages {
  VasBump = 'vas-bump',
  VasClosetPromo = 'vas-closet-promo',
  VasClosetPromoStats = 'vas-closet-promo-stats',
  VasFeaturedCollection = 'vas-featured-collection',
}
