'use client'

import { useSelector } from 'react-redux'
import { Button, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import useTranslate from 'hooks/useTranslate'

import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { ClosetPromotionOrderModel } from 'types/models'

type Props = {
  order: ClosetPromotionOrderModel
  onShowSalesTaxModal: () => void
}

const OrderSummary = ({ order, onShowSalesTaxModal }: Props) => {
  const translate = useTranslate('closet_promotion.order_confirmation_modal.order_summary')

  const locale = useSelector(getLocale)

  const { salesTax, total, isTaxCovered, payable, effectiveDays, discount, discountPercentage } =
    order

  function renderPrice() {
    const dayCount = translate(
      'price_day_count',
      { count: effectiveDays },
      { count: effectiveDays },
    )

    return (
      <Cell styling={Cell.Styling.Tight} suffix={formatCurrencyAmount(total, locale)}>
        <Text text={`${translate('price')} ${dayCount}`} />
      </Cell>
    )
  }

  function renderDiscount() {
    if (parseFloat(order.discount.amount) <= 0) return null

    return (
      <Cell
        styling={Cell.Styling.Tight}
        suffix={formatCurrencyAmount(discount, locale, { formatAsNegative: true })}
      >
        <Text
          theme="success"
          text={translate('discount', {
            percentage: discountPercentage,
          })}
        />
      </Cell>
    )
  }

  function renderSalesTax() {
    if (!salesTax?.amount) return null

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          suffix={`${formatCurrencyAmount(salesTax, locale)}`}
          testId="closet-promotion-order-summary-sales-tax"
        >
          <div className="u-flexbox u-align-items-center">
            <Text
              text={translate('sales_tax')}
              testId="closet-promotion-order-summary-sales-tax--title"
            />
            <Button
              icon={
                <Icon
                  name={InfoCircle16}
                  color={Icon.Color.GreyscaleLevel3}
                  aria={{
                    'aria-label': translate('a11y.sales_tax_info'),
                  }}
                />
              }
              inline
              size={Button.Size.Small}
              styling={Button.Styling.Flat}
              onClick={onShowSalesTaxModal}
              testId="closet-promotion-order-summary-sales-tax--info-circle"
            />
          </div>
        </Cell>
        {isTaxCovered && (
          <Cell
            styling={Cell.Styling.Tight}
            body={
              <Text
                text={translate('sales_tax_covered_by_vinted')}
                highlightTheme="amplified"
                type={Text.Type.Subtitle}
                testId="closet-promotion-order-summary-sales-tax--covered-by-vinted"
              />
            }
          />
        )}
      </>
    )
  }

  function renderTotal() {
    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Cell
          styling={Cell.Styling.Tight}
          title={translate('total')}
          suffix={<Text type={Text.Type.Title} text={formatCurrencyAmount(payable, locale)} />}
        />
      </>
    )
  }

  return (
    <Cell>
      <SeparatedList separator={<Spacer size={Spacer.Size.Small} />}>
        {renderPrice()}
        {renderDiscount()}
      </SeparatedList>
      {renderSalesTax()}
      {renderTotal()}
    </Cell>
  )
}

export default OrderSummary
