'use client'

import { Card, Cell, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import CloseButton from '../CloseButton'

type Props = {
  onClose?: () => void
}

const StaticBanner = ({ onClose }: Props) => {
  const translate = useTranslate('shipping_fees_applied_info_banner')

  return (
    <Card testId="shipping-fees-applied-banner">
      <Cell
        title={<Text text={translate('title')} type={Text.Type.Subtitle} />}
        theme="transparent"
        suffix={onClose && <CloseButton onClick={onClose} />}
      />
    </Card>
  )
}

export default StaticBanner
