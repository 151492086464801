import { useMemo } from 'react'

import useAbTest from 'hooks/useAbTest'
import useLocation from 'hooks/useLocation'
import useBreakpoint from 'hooks/useBreakpoint'

import { getTabName } from '../utils/tabs'
import { TABS } from '../constants'
import { TabName } from '../types'

const useTabs = () => {
  const areTabsAllowed = useBreakpoint().portables

  const isQ2HoldoutEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q2' })?.variant !== 'off'
  const isDesignerTabAllowed = areTabsAllowed && isQ2HoldoutEnabled
  const isDesignerTabEnabled =
    useAbTest({
      abTestName: 'hvf_vertical',
      shouldTrackExpose: isDesignerTabAllowed,
      shouldTrackOncePerSessionDay: true,
    })?.variant === 'on' && isDesignerTabAllowed

  const tabs = useMemo(() => {
    const initialTabs: Array<(typeof TABS)[TabName]> = [TABS.all]

    if (isDesignerTabEnabled) initialTabs.push(TABS.designer)

    return initialTabs
  }, [isDesignerTabEnabled])

  const location = useLocation()
  const currentTab = getTabName(location.urlParams.tab)
  const shouldShowTabs = tabs.length > 1

  const navigateToTab = (tab: string) =>
    location.pushHistoryState(`/?tab=${tab}`, undefined, { shallow: true })

  return {
    navigateToTab,
    shouldShowTabs,
    isTabSelected: shouldShowTabs || currentTab !== TABS.all.name,
    currentTab: TABS[currentTab],
    tabs,
  }
}

export default useTabs
