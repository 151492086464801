'use client'

import { Cell, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import { getScreenName } from 'state/screen/selectors'
import { Screen } from 'constants/tracking/screens'
import useTabs from 'pages/Home/hooks/useTabs'

import CloseButton from '../CloseButton'
import StaticBanner from '../StaticBanner'

type Props = {
  onClose?: () => void
}

const TAB_HEIGHT = 46 // equals to $header-tabs-height
const HEADER_TOTAL_HEIGHT = 100 // equals to $header-total-height

const StickyBanner = ({ onClose }: Props) => {
  const screenName = useSelector(getScreenName)
  const isHomePage = screenName === Screen.NewsFeed

  const { shouldShowTabs } = useTabs()
  const isTabsVisible = shouldShowTabs && isHomePage

  const [ref, inView] = useInView({
    rootMargin: isTabsVisible
      ? `-${HEADER_TOTAL_HEIGHT + TAB_HEIGHT}px 0px 0px 0px`
      : `-${HEADER_TOTAL_HEIGHT}px 0px 0px 0px`, // Adjust the top margin by header height
  })
  const translate = useTranslate('shipping_fees_applied_info_banner')

  const renderStickyBanner = () => (
    <div
      data-testid="shipping-fees-applied-banner-sticky"
      className={classNames(
        'shipping-fees-applied-banner--sticky',
        isTabsVisible && 'shipping-fees-applied-banner--sticky-with-tabs',
      )}
    >
      <div className="container u-padding-vertical-medium">
        <Cell
          styling={Cell.Styling.Tight}
          title={<Text text={translate('title')} type={Text.Type.Subtitle} />}
          suffix={onClose && <CloseButton onClick={onClose} />}
        />
      </div>
    </div>
  )

  return (
    <>
      <StaticBanner onClose={onClose} />
      <div ref={ref} />
      {!inView && renderStickyBanner()}
    </>
  )
}

export default StickyBanner
